import * as React from "react"

const Button = ({ title }) => {
  return (
    <a
      href="mailto: rose.fabra.design@gmail.com"
      className="py-4 px-10 bg-black border-1 hover:bg-green hover:border-black-2 font-bold text-white drop-shadow-[3px_3px_1px_rgba(60,170,152)] hover:drop-shadow-[3px_3px_1px_rgba(0,0,0,0.9)] no-underline"
    >
      {title}
    </a>
  )
}

export default Button

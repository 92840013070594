import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import roseProfilePhoto from "../images/home-rose-profile.jpg"
import homePageBgImage from "../images/homepage-banner.png"
import commitedImage from "../images/features-icons/commited.png"
import enthsiastImage from "../images/features-icons/enthusiast.png"
import magnifierImage from "../images/features-icons/magnifier.png"
import rewardImage from "../images/features-icons/reward.png"
import LetsMakeItVisual from "../images/Letsmakeitvisual-banner.png"
import Button from "../components/button"

const myFeatures = [
  {
    title: "Commited",
    image: `${commitedImage}`,
    text: `I like to be responsible for the design and work closely with the customer
    to get to the hone of what’s needed.`,
  },
  {
    title: "Eye for detail",
    image: `${magnifierImage}`,
    text: `Focusing with the quality, look and feel of the content that will be
seen across different devices.`,
  },
  {
    title: "Enthusiast",
    image: `${enthsiastImage}`,
    text: `It feels rewarding when I can conceptualize a design to an end product
that will be printed in a physical form.`,
  },
  {
    title: "Creative",
    image: `${rewardImage}`,
    text: `Happens when I have spare time and I grab my iPad to freestyle and let
my imagination fly.`,
  },
]

const IndexPage = ({ data }) => {
  console.log(data)
  const projects = data.allProjectsJson.nodes

  console.log("projects", projects)

  return (
    <Layout>
      <Seo title="Home" />
      <div className="">
        <div
          className="intro mx-auto"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${homePageBgImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="flex container justify-center items-center min-h-[50vh] md:min-h-[90vh] mb-28">
            {" "}
            <h1 className="text-center text-4xl md:text-7xl md:leading-relaxed font-bold uppercase px-10 leading-normal ">
              I'm a creative <br />
              graphic <br /> designer <span className="text-green">&</span>{" "}
              illustrator<span className="text-green">.</span>
            </h1>
          </div>
        </div>

        <div className="intro container mx-auto mb-28 px-3" id="about">
          <div className="md:columns-2 sm:columns-1 gap-5">
            <div className="max-w-[600px] mx-auto">
              <img
                className="block mx-auto"
                src={roseProfilePhoto}
                alt="Rose Fabra"
              />
            </div>

            <div className="py-20">
              <p className="text-lg font-medium text-justify ">
                Hi, Rose here!
                <br /> <br />
                A graphic designer that specialises in Branding, Web and Digital
                design.
                <br />
                <br />I have 2+ years of experience designing for companies and
                individuals in a variety of projects to create or revamp their
                digital/print marketing materials, branding, logos and websites.{" "}
                <br />
                <br />
                I thrive when I’m being the most creative and I found my passion
                when I started designing and illustrating.
                <br />
                <br />
                Currently, I’m still open to all kinds of design work, where I
                can have the chance to collaborate in different projects, brands
                and to progress within the industry delivering good designs and
                experiences to the customers.
                <br />
                <br />
                Don't hesitate to contact me about your design needs!
              </p>
              <br /> <br />
              <Button
                onClick={"mailto:rose.fabra.design@gmail.com"}
                title="Contact Me!"
              ></Button>
            </div>
            <p></p>
          </div>
        </div>

        <div className="intro mx-auto bg-black mb-28 py-28 px-3">
          <div className="container">
            <h2 className="text-5xl text-white uppercase font-bold pb-14">
              My Attributes<span className="text-green">.</span>
            </h2>
            <div className="lg:columns-4">
              {myFeatures.map(feature => (
                <div className="mx-auto py-3">
                  <img
                    width="125"
                    className="block mx-auto"
                    src={feature.image}
                    alt="alt"
                  />
                  <h3 className="text-white text-center text-lg font-bold mb-6">
                    {feature.title}
                  </h3>
                  <p className="text-white text-center">{feature.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="intro mx-auto mb-28 py-14 px-3">
          <div className="container">
            <img src={LetsMakeItVisual} alt="Let's make it visual" />
          </div>
        </div>

        <div className="intro mx-auto mb-28 px-3" id="projects-section">
          <div className="container">
            <h2 className="text-5xl text-black uppercase font-bold pb-14">
              Latest Projects<span className="text-green">.</span>
            </h2>
          </div>
          <div className="lg:columns-2">
            {projects.map(project => (
              <Link to={project.slug} className="inline-grid">
                <div className="mx-auto py-3 overflow-hidden">
                  <div className="overflow-hidden relative">
                    <img
                      className="block m-0 hover:scale-110 ease-in-out duration-300 transform-gpu"
                      src={project.previewImage.src.publicURL}
                      alt="alt"
                    />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export const query = graphql`
  query {
    allProjectsJson(sort: { fields: position, order: ASC }) {
      nodes {
        previewImage {
          src {
            publicURL
          }
        }
        slug
        title
      }
    }
  }
`

export default IndexPage
